<template>
  <section class="wrapper" id="block-1">
    <div class="content">
      <img
        src="@/assets/images/about-problem-hero.png"
        alt="hero-img"
        class="image"
      />
      <h2 class="title">gts как решение</h2>
    </div>
    <p class="description no-uppercase">
      Технология «GTS» – серверная операционная система линейно масштабируемого
      кластера, предназначенная для запуска и диспетчеризации любых облачных
      приложений.
    </p>
  </section>
</template>

<script>
export default {
  name: "AboutGtsBlock1",
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-top: 160px;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.image {
  max-width: 408px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  position: relative;
  height: 90vh;
}

.title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 75px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.description {
  max-width: 900px;
  width: 100%;
  font-size: 36px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: -0.2879px;
  text-align: center;
}

@media (max-width: 920px) {
  .wrapper {
    margin-bottom: 0;
  }

  .description {
    font-size: 36px;
    line-height: 40px;
    max-width: 75%;
  }
}

@media (max-width: 630px) {
  .title {
    font-size: 36px;
    line-height: 40px;
  }

  .description {
    font-size: 32px;
    line-height: 36px;
    max-width: 75%;
  }
}

@media (max-width: 430px) {
  .title {
    font-size: 30px;
    line-height: 36px;
  }

  .description {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 40px;
    max-width: 75%;
  }
}
</style>
