<template>
  <section class="wrapper" ref="blockSection" id="block-16">
    <div class="d-flex align-items-center justify-content-center flex-column">
      <div
        class="card-wrapper"
        v-for="card in getCards"
        :key="card.id"
        @click="openModal(card.id)"
      >
        <div
          class="card"
          ref="card"
          :style="`
            width: 100%;
            background-image: url('/images/${card.backgroundTail}');
          `"
        >
          <span class="card-title">{{ card.title }}</span>
          <div class="card-description">
            {{ card.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="divider-vertical my-15" style="height: 108px"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProblemTailBlock",
  methods: {
    openModal(id) {
      this.$emit("showProblemModal", id);
    },
  },
  computed: {
    ...mapGetters(["getCards"]),
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
  padding: 0 20px;
}

.card-wrapper {
  overflow: hidden;
  margin: 12px;
  width: 100%;
  max-width: 800px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border: 2px solid #37363a;
  padding: 25px;
  border-radius: 30px;
  background-size: cover;
  width: 100%;
  cursor: pointer;
  opacity: 0.8;
}

.card:hover {
  opacity: 1;
}

.card-title {
  border: 1px solid #ffffff;
  padding: 8px 20px;
  border-radius: 24px;
  margin-bottom: 20px;
}

.card-description {
  font-size: 24px;
  line-height: 28px;
  font-weight: 550;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 600px) {
  .card-title {
    font-size: 32px;
    line-height: 36px;
  }

  .card-description {
    font-size: 36px;
    line-height: 42px;
  }
}
</style>
