<template>
  <section class="wrapper" ref="blockSection" id="block-19">
    <h2 class="title mb-15 animation-1">
      Почему мы<br />
      уверены в своем<br />
      решении?
    </h2>
    <div class="card animation-2">
      <h3 class="sub-title outlined mb-10">01</h3>
      <h2 class="title mb-10 animation-3">
        мы не создаем<br />
        ничего нового
      </h2>
      <div class="animation-4 d-flex flex-column align-items-center">
        <h3 class="sub-title">
          Хотя это может показаться новым, технология<br />
          GTS основана на проверенных решениях с<br />
          высокой степенью технологической<br />
          готовности, как УГТ 9*, которая обслуживала<br />
          миллионы пользователей, включая ЦБ России,<br />
          ФАПСИ, госдуму, Аэрофлот и другие компании
        </h3>
        <h3 class="sub-title my-5">
          GTS не несет технических и венчурных рисков.
        </h3>
        <div class="d-flex align-items-center justify-content-between w-100">
          <img src="@/assets/images/banK-of-Russia.png" alt="bank-of-Russia" />
          <img src="@/assets/images/herb.png" alt="herb-of-Russia" />
          <img
            src="@/assets/images/government.png"
            alt="government-of-Russia"
          />
          <img src="@/assets/images/aeroflot.png" alt="aeroflot" />
        </div>
        <h4 class="description my-5">
          *УГТ - Уровень готовности технологий. Утв. Минобрнауки<br />
          России от 11.07.2017 N ГТ-57/14вн.
        </h4>
        <h4 class="link">
          Источник: <span class="underline">МинОбрНауки России</span>
        </h4>
      </div>
    </div>
    <div class="card animation-5" style="position: relative; top: -120px">
      <h3 class="sub-title outlined mb-10">02</h3>
      <h2 class="title mb-15">
        40-летний опыт<br />
        технологии
      </h2>
      <h3 class="sub-title">
        Технология GTS — это третье поколение систем<br />
        «БАЙКОНУР» и «ОБЬ», адаптированное под<br />
        современные требования КИИ. Эти<br />
        отечественные системы по результатам<br />
        технического анализа были более<br />
        совершенны, чем транзакционные системы IBM<br />
        CICS TS, которые остаются флагманом в США.
      </h3>
      <h3 class="sub-title my-5">
        Главные архитекторы систем «Обь» и<br />
        «Байконур» - ведущие специалисты команды<br />
        разработки GTS.
      </h3>
      <div class="divider-horizontal w-100 my-5"></div>
      <h3 class="tail-title">
        Baikonur показал<br />
        кратное увеличение<br />
        производительности
      </h3>
      <a
        href="https://www.osp.ru/"
        class="link my-5"
        target="_blank"
        rel="noopener noreferrer"
        >Источник: <span class="underline">osp.ru</span></a
      >
      <img src="@/assets/images/image-graphic-block-19.png" alt="graphic" />
    </div>
    <div class="content-card animation-6">
      <img
        src="@/assets/images/image-block-19-background.png"
        alt="background"
      />
      <div class="content">
        <h3 class="sub-title outlined mb-10">03</h3>
        <h2 class="title mb-10">
          Россия на пороге<br />
          цифрового прорыва
        </h2>
        <h3 class="sub-title">
          Еще недавно такой проект казался<br />
          невозможным из-за монополизации<br />
          технологий американскими корпорациями.
        </h3>
        <h3 class="sub-title">
          Сегодня Россия имеет шанс создать новый<br />
          цифровой мир и предложить решение для<br />
          выхода из глобального кризиса.
        </h3>
        <h3 class="sub-title">
          Опираясь на наследие передовой советской<br />
          школы кибернетики, наша команда под<br />
          руководством легендарных разработчиков,<br />
          готова доказать, <span class="outlined-white green">что...</span>
        </h3>
      </div>
    </div>
    <div class="blur-block animation-7">
      <img src="@/assets/images/green-blur.png" alt="background" />
      <h2 class="title-blur-block title">
        кибернетическая<br />
        экономика — это ключ к<br />
        преодолению хаоса<br />
        цифровизации.
      </h2>
    </div>
    <div
      class="divider-horizontal my-0"
      style="position: relative; top: -150px"
    ></div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection19",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 90%",
        end: "bottom 50%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.addLabel("1", 0);
    timeline.addLabel("2", 0.6);
    timeline.addLabel("3", 0.7);
    timeline.addLabel("4", 0.7);
    timeline.addLabel("5", 1);
    timeline.addLabel("6", 2);
    timeline.addLabel("7", 2.5);

    timeline.fromTo(
      blockSection.querySelector(".animation-1"),
      { autoAlpha: "0", scale: 1.5 },
      { autoAlpha: "1", scale: 1, duration: 0.5 },
      "1"
    );

    timeline.fromTo(
      blockSection.querySelectorAll(".animation-2"),
      { autoAlpha: "0", y: 180 },
      { autoAlpha: "1", y: 0, duration: 0.5 },
      "2"
    );

    timeline.fromTo(
      blockSection.querySelectorAll(".animation-3"),
      { clipPath: "inset(0 0 100% 0)", scale: 1 },
      {
        clipPath: "inset(0 0 0% 0)",
        scale: 1,
        duration: 0.5,
        ease: "linear",
      },
      "3"
    );

    timeline.fromTo(
      blockSection.querySelector(".animation-4"),
      { autoAlpha: "0", y: 100, scaleX: 1.5 },
      { autoAlpha: "1", y: 0, scaleX: 1, duration: 0.5 },
      "4"
    );

    timeline.fromTo(
      blockSection.querySelector(".animation-5"),
      { autoAlpha: "0", y: 180 },
      { autoAlpha: "1", y: 0, duration: 0.5 },
      "5"
    );

    timeline.fromTo(
      blockSection.querySelector(".animation-6"),
      { autoAlpha: "0", scale: 0.2 },
      { autoAlpha: "1", scale: 1, duration: 0.5 },
      "6"
    );

    timeline.fromTo(
      blockSection.querySelector(".animation-7"),
      { autoAlpha: "0", scale: 0.5 },
      { autoAlpha: "1", scale: 1, duration: 0.5 },
      "7"
    );
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  text-transform: uppercase;
}

.card {
  width: 100%;
  max-width: 860px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 70px;
  margin-top: 120px;
  border-radius: 146px;
  background: linear-gradient(180deg, #0e240b, rgba(0, 0, 0, 1)) padding-box,
    linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 1))
      border-box;

  border: 2px solid transparent;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.tail-title {
  font-size: 32px;
  font-weight: 550;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.link {
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.3329px;
  text-align: right;
  opacity: 0.5;
  text-transform: none;
}

.description {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.2879px;
  text-align: center;
  opacity: 0.5;
  text-transform: none;
}

.content-card {
  position: relative;
  top: -150px;
  width: 100%;
  max-width: 860px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 70px;
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blur-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -350px;
  width: 100%;
  max-width: 860px;
  margin-bottom: -200px;
}

.title-blur-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
