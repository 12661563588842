<template>
  <section class="wrapper" id="contact-form">
    <div class="left-side">
      <h2 class="title">
        ГОТОВЫ<br />
        ПРИСОЕДИНИТЬСЯ<br />
        <img src="../assets/icons/icon-red-onto.png" alt="logo" />
        К НОВОЙ онто-<br />
        РЕАЛЬНОСТИ?
      </h2>
    </div>
    <div class="right-side">
      <input
        v-model="info.name"
        type="text"
        :class="{ 'is-invalid': errors.name }"
        class="input-text"
        placeholder="Ваше имя"
      />
      <input
        v-model="info.email"
        type="text"
        :class="{ 'is-invalid': errors.email }"
        class="input-text"
        placeholder="E-mail"
      />
      <textarea
        v-model="info.comment"
        :class="{ 'is-invalid': errors.comment }"
        class="input-text"
        placeholder="Комментарий"
      />
      <button class="button-submit" type="button" @click="submitForm">
        Отправить
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      info: {
        name: "",
        email: "",
        comment: "",
      },
      errors: {
        name: false,
        email: false,
        comment: false,
      },
      googleSheetUrl:
        "https://script.google.com/macros/s/AKfycbwZsMUmPQgo1HbIjvUTNg4fZ5vGmmtnlwuHi3IogO0EAJsbqK2_eopt-ryE6W7ZFZhoDw/exec",
    };
  },
  methods: {
    validateForm() {
      this.errors.name = this.info.name.length <= 3;
      this.errors.email = !this.info.email.includes("@");
      this.errors.comment = this.info.comment.length < 1;

      return !this.errors.name && !this.errors.email && !this.errors.comment;
    },
    async submitForm() {
      if (this.validateForm()) {
        try {
          const response = await fetch(this.googleSheetUrl, {
            method: "POST",
            body: JSON.stringify(this.info),
          });
          const result = await response.json();

          if (result.status === "success") {
            alert("Данные успешно отправлены!");
            this.resetForm();
          } else {
            alert("Ошибка при отправке данных. Попробуйте снова.");
          }
        } catch (error) {
          console.error("Ошибка при отправке данных:", error);
          alert("Произошла ошибка. Пожалуйста, попробуйте позже.");
        }
      } else {
        alert("Пожалуйста, заполните форму корректно.");
      }
    },
    resetForm() {
      this.info.name = "";
      this.info.email = "";
      this.info.comment = "";
      this.errors = {
        name: false,
        email: false,
        comment: false,
      };
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 1400px;
  margin: 150px auto;
  padding: 25px 35px;
  text-transform: uppercase;
  background-color: #ffffff;
  border-radius: 40px;
  color: #000000;
}

.left-side {
  width: 70%;
  padding: 20px;
  display: flex;
}

.title {
  font-family: "Adieu Light", sans-serif;
  font-size: 64px;
  font-weight: 300;
  line-height: 76px;
  letter-spacing: -0.3811px;
  text-align: left;
  display: inline-block;
}

.right-side {
  width: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.input-text {
  width: 100%;
  padding: 20px;
  border-radius: 100px;
  border: 2px solid #00000033;
  box-sizing: border-box;
  margin-bottom: 10px;
  resize: none;
}

.is-invalid {
  border: 2px solid red;
}

.button-submit {
  width: 100%;
  padding: 20px;
  border-radius: 100px;
  background-color: #000000;
  color: #ffffff;
  font-size: 20px;
  font-weight: 550;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-submit:hover {
  background-color: #00000077;
}

@media screen and (max-width: 1300px) {
  .title {
    font-size: 56px;
    line-height: 64px;
  }

  .title img {
    width: 56px;
  }
}

@media screen and (max-width: 1100px) {
  .title {
    font-size: 46px;
    line-height: 54px;
  }

  .title img {
    width: 46px;
  }
}

@media screen and (max-width: 1100px) {
  .wrapper {
    flex-direction: column;
  }

  .right-side {
    width: 100%;
    padding: 0;
    gap: 0;
    align-items: center;
    justify-content: center;
  }

  .left-side {
    width: 100%;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    overflow-wrap: break-word;
    padding: 20px 0;
  }
}

@media screen and (max-width: 670px) {
  .title {
    font-size: 26px;
    line-height: 34px;
  }

  .title img {
    width: 26px;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 22px;
    line-height: 27px;
  }

  .title img {
    width: 22px;
  }
}
</style>
