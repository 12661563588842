<template>
  <div class="page" v-if="!isMobile">
    <AboutGtsBlock1 />
    <AboutGtsBlock2 />
    <AboutGtsBlock3 />
    <AboutGtsBlock4 />
    <AboutGtsBlock5 />
    <AboutGtsBlock6 />
    <AboutGtsBlock7 />
    <ContactForm />
  </div>
  <div class="page" v-if="isMobile">
    <MobileAboutGtsBlock />
  </div>
</template>

<script>
import eventBus from "@/utils/eventsBus";
import AboutGtsBlock1 from "@/components/AboutGts/AboutGtsBlock1.vue";
import AboutGtsBlock2 from "@/components/AboutGts/AboutGtsBlock2.vue";
import AboutGtsBlock3 from "@/components/AboutGts/AboutGtsBlock3.vue";
import AboutGtsBlock4 from "@/components/AboutGts/AboutGtsBlock4.vue";
import AboutGtsBlock5 from "@/components/AboutGts/AboutGtsBlock5.vue";
import AboutGtsBlock6 from "@/components/AboutGts/AboutGtsBlock6.vue";
import AboutGtsBlock7 from "@/components/AboutGts/AboutGtsBlock7.vue";
import ContactForm from "@/components/ContactForm.vue";
import MobileAboutGtsBlock from "@/components/mobileAboutGts/MobileAboutGtsBlock.vue";

export default {
  name: "AboutGtsView",
  components: {
    MobileAboutGtsBlock,
    ContactForm,
    AboutGtsBlock7,
    AboutGtsBlock6,
    AboutGtsBlock5,
    AboutGtsBlock1,
    AboutGtsBlock2,
    AboutGtsBlock3,
    AboutGtsBlock4,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 920;
    },
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  mounted() {
    eventBus.emit("loadingTrigger", true);
    window.addEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style scoped></style>
