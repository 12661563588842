<template>
  <section class="wrapper" ref="blockSection" id="block-6">
    <div class="wrapper-content">
      <div class="fide-wrap col">
        <div
          class="d-flex flex-column align-items-start justify-content-between left-side"
        >
          <h4 class="sub-title">ЗАПРЕТ СО СТОРОНЫ США</h4>
          <h3 class="title my-10">
            США ЗАПРЕТИЛИ <br />
            <img
              src="@/assets/icons/material-symbols-light_lock-outline.svg"
              alt=""
            />РОССИИ РЯД <br />
            УСЛУГ В СФЕРЕ it
          </h3>
          <h5 class="description">
            Минфин США в сотрудничестве с Госдепом выпустил новое определение
            (E.O. 14071), запрещающее услуги IT- поддержки и облачные сервисы
            для ПО и управления предприятием.
          </h5>
          <h5 class="description pt-5">
            вступление в силу<br /><span class="red"
              >12 сентября 2024 года.</span
            >
          </h5>
        </div>
      </div>

      <div
        class="col d-flex flex-column align-items-center justify-content-end right-side"
      >
        <img src="@/assets/images/image-block-6.png" alt="section6" />
        <a
          href="https://www.rbc.ru/technology_and_media/12/06/2024/6669be809a79473bda602920?ysclid=lz1q6agyh3553399805"
          class="source-link"
          target="_blank"
          rel="noopener noreferrer"
          >Источник: <span class="underline">rbc.ru</span></a
        >
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection6",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 60%",
        end: "top 0%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });
    timeline.fromTo(
      blockSection.querySelector(".right-side"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.5 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".right-side"),
      { translateX: "50%" },
      { translateX: 0, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".right-side"),
      { scale: 1.1 },
      { scale: 1, duration: 1 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".sub-title"),
      { translateX: "-101%" },
      { translateX: 0, duration: 1 },
      "-=2"
    );

    timeline.fromTo(
      blockSection.querySelector(".description"),
      { translateX: "-100%" },
      { translateX: 0, duration: 1 },
      "-=2"
    );

    timeline.fromTo(
      blockSection.querySelector(".title"),
      { translateX: "-100%" },
      { translateX: 0, duration: 1 },
      "-=1.5"
    );
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.wrapper-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}

.description {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}

.source-link {
  position: absolute;
  bottom: 17%;
  left: 21%;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  opacity: 0.5;
}

.col {
  position: relative;
  width: 50%;
}

.fide-wrap {
  overflow: hidden;
}
.red {
  color: #fc3f1d;
}

@media (max-width: 1100px) {
  .title {
    font-size: 28px;
    line-height: 38px;
  }

  .description {
    font-size: 18px;
    line-height: 32px;
  }
}

@media (max-width: 780px) {
  .title {
    font-size: 22px;
    line-height: 28px;
  }

  .description {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
