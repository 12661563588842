<template>
  <div class="header">
    <div class="wrapper">
      <div class="d-flex align-items-center">
        <div class="mr-5">
          <router-link to="/">
            <img src="@/assets/icons/Home-link.svg" alt="Logo" class="logo" />
          </router-link>
        </div>
        <div
          class="burger-container"
          v-if="getBurgerItems.length"
          ref="burgerMenu"
        >
          <button class="burger-btn" @click="toggleBurgerMenu">
            <span class="mr-3">Ситуация на рынке</span>
            <img src="@/assets/icons/menu-icon.png" alt="Burger Menu" />
          </button>
          <div v-if="isBurgerMenuOpen" class="burger-menu">
            <span class="burger-title">Содержание</span>
            <div class="horizontal-line"></div>
            <span
              v-for="item in getBurgerItems"
              :key="item.link"
              class="burger-menu-item"
              @click="scrollToAnchor(item)"
            >
              {{ item.title }}
            </span>
          </div>
        </div>
      </div>
      <div class="menu">
        <router-link to="/" class="menu-item mr-2">Главная</router-link>
        <router-link to="/about-problem" class="menu-item mr-2"
          >О Проблеме</router-link
        >
        <router-link to="/about-gts" class="menu-item mr-2">О gts</router-link>
        <a
          href="https://ontonet.org/"
          class="menu-item mr-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          блог
        </a>
        <div class="d-flex align-items-center">
          <router-link to="/contacts" class="btn-link">
            <span class="menu-item">Принять Участие</span>
            <img src="@/assets/icons/icon_go_to.svg" alt="Go to" class="ml-7" />
          </router-link>
        </div>
      </div>
      <div class="menu-mobile ml-2">
        <img
          src="@/assets/icons/menu-mobile-icon.png"
          alt="burger-menu"
          @click="openMobileMenu"
        />
      </div>
    </div>
  </div>
</template>

<script type="js">
import { mapGetters } from "vuex";
import eventBus from "@/utils/eventsBus";

export default {
  name: "HeadMenu",
  data() {
    return {
      isBurgerMenuOpen: false,
    };
  },
  methods: {
    scrollToAnchor(item) {
      console.log(item);
      window.locoScroll.scrollTo(item.path, {
        offset: item.offset,
      });
      this.isBurgerMenuOpen = false;
    },
    toggleBurgerMenu() {
      this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
    },
    handleClickOutside(event) {
      const isClickInside = this.$refs.burgerMenu && this.$refs.burgerMenu.contains(event.target);
      if (!isClickInside) {
        this.isBurgerMenuOpen = false;
      }
    },
    handleScroll() {
      this.isBurgerMenuOpen = false;
    },
    openMobileMenu() {
      eventBus.emit("openMobileMenu", this.getBurgerItems);
    },
  },
  computed: {
    ...mapGetters(["getBurgerItems"]),
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    window.addEventListener("wheel", this.handleScroll);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("wheel", this.handleScroll);
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 14px 18px 14px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0.95;
  border-radius: 100px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
  min-width: 149px;
}

.menu {
  display: flex;
  align-items: center;
  max-width: 750px;
  min-width: 600px;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
  padding-left: 20px;
}

.menu-item {
  text-transform: uppercase;
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 30px;
  border: none;
  background-color: #fff;
  color: var(--black);
  text-decoration: none;
  border-radius: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.burger-container {
  position: relative;
}

.burger-btn {
  padding: 7px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.2845px;
  text-align: left;
  color: white;
  text-transform: uppercase;
  white-space: nowrap;
}

.burger-menu {
  position: absolute;
  top: 67px;
  right: -25px;
  z-index: 10;
  opacity: 0.95;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 20px;
  padding: 20px 17px;
  min-width: 260px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.burger-menu::before {
  content: "";
  position: absolute;
  top: -20px;
  right: 32px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
}

.burger-menu::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  filter: blur(1px);
  z-index: -1;
}

.burger-title {
  font-size: 20px;
  font-weight: 550;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  margin: 10px 0;
}

.burger-menu-item {
  display: block;
  margin-bottom: 12px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.burger-menu-item:last-child {
  margin-bottom: 0;
}

.menu-mobile {
  display: none;
}

@media (max-width: 1150px) {
  .menu,
  .burger-btn {
    font-size: 12px;
    min-width: 200px;
  }

  .btn-link {
    padding: 3px 3px 3px 20px;
  }

  .btn-link > img {
    width: 24px;
    margin-left: 10px !important;
  }
}

@media (max-width: 900px) {
  .menu {
    display: none;
  }

  .menu-mobile {
    display: block;
  }
}

@media (max-width: 500px) {
  .wrapper {
    padding-left: 20px;
  }

  .logo {
    max-width: 150px;
  }

  .burger-container {
    display: none;
  }
}
</style>
