<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="close">
    <div class="modal-wrapper">
      <div class="modal-content">
        <slot />
        <img
          v-if="showClose"
          src="@/assets/icons/carbon_close-outline.png"
          alt="close"
          class="close-btn"
          @click="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit("update:isVisible");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(9px);
  z-index: 100;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.modal-content {
  background: #000000;
  backdrop-filter: blur(9px);
  border: 2px solid #676767;
  border-radius: 46px;
  z-index: 101;
}

.close-btn {
  position: absolute;
  top: 4%;
  right: 4%;
  cursor: pointer;
  width: 48px;
  height: 48px;
  opacity: 0.8;
}

.close-btn:hover {
  opacity: 1;
  scale: 1.05;
}

@media (max-width: 760px) {
  .close-btn {
    position: absolute;
    top: 4%;
    right: 10%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    opacity: 0.8;
  }

  .modal-content {
    overflow-y: auto;
    border-radius: 20px;
  }
}
</style>
