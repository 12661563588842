<template>
  <div class="page px-4">
    <ContactForm />
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import eventBus from "@/utils/eventsBus";

export default {
  name: "ContactsView",
  components: { ContactForm },
  data() {
    return {
      showContent: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 920;
    },
  },
  async mounted() {
    eventBus.emit("loadingTrigger", true);
    if (!this.isMobile) setTimeout(() => window.locoScroll.stop(), 600);
  },
};
</script>

<style scoped></style>
