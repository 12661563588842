<template>
  <div class="page-mobile">
    <AboutProblemHero />
    <img
      src="@/assets/images/About-problem-1-title.svg"
      alt="hero"
      class="mb-5"
      style="width: 40%"
    />
    <img
      src="@/assets/images/About-problem-1-subtitle.svg"
      alt="hero"
      class="w-75"
    />
    <div class="divider-vertical-dashed my-4" style="height: 30px"></div>
    <img
      src="@/assets/images/About-problem-1-subtitle-2.svg"
      alt="hero"
      class="w-75"
    />
    <div class="block-wrapper about-problem-1-content">
      <img
        src="@/assets/images/About-problem-1-content.svg"
        alt="hero"
        class="mt-100"
      />
      <h3 class="source">
        Источник:
        <a
          href="https://pro.rbc.ru/demo/615c08c49a794751c17e20f8"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >rbc.ru</a
        >
      </h3>
    </div>
    <img
      src="@/assets/images/About-problem-1-content-2.svg"
      alt="hero"
      class="mt-100 w-75"
    />
    <div class="block-wrapper about-problem-1-content-3">
      <img
        src="@/assets/images/About-problem-1-content-3.svg"
        alt="hero"
        class="mt-100 px-5"
      />
      <h3 class="source">
        Источник:
        <a
          href="https://forbes.kz/articles/tendentsii_kiberbezopasnosti_top-5_ugroz_dlya_malogo_i_srednego_biznesa_v_2023_godu"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >forbes.kz</a
        >
      </h3>
    </div>
    <div class="block-wrapper about-problem-1-content-4">
      <img
        src="@/assets/images/About-problem-1-content-4.svg"
        alt="hero"
        class="mb-6"
      />
      <h3 class="source">
        Источник:
        <a
          href="https://rg.ru/2024/04/09/dannye-dlia-obucheniia-ii-zakanchivaiutsia.html"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >rg.ru</a
        >
      </h3>
    </div>
    <img
      src="@/assets/images/About-problem-1-content-5.svg"
      alt="hero"
      class="mt-100 px-5"
    />
    <div class="block-wrapper about-problem-1-content-6">
      <img src="@/assets/images/About-problem-1-content-6.svg" alt="hero" />
      <div class="tile-wrapper d-flex flex-column">
        <a
          href="https://accesstomarkets.org/"
          target="_blank"
          rel="noopener noreferrer"
          class="google"
        ></a>
        <a
          href="https://www.economicliberties.us/our-work/the-truth-about-google-facebook-and-small-businesses/"
          target="_blank"
          rel="noopener noreferrer"
          class="facebook"
        ></a>
        <a
          href="https://economictimes.indiatimes.com/news/international/us/what-is-amazons-secret-pricing-strategy-and-what-is-the-ftc-lawsuit-alleging-cost-manipulation-tactics/articleshow/104966949.cms?from=mdr"
          target="_blank"
          rel="noopener noreferrer"
          class="amazon"
        ></a>
        <a
          href="https://themarkup.org/working-for-an-algorithm/2022/03/01/secretive-algorithm-will-now-determine-uber-driver-pay-in-many-cities"
          target="_blank"
          rel="noopener noreferrer"
          class="uber"
        ></a>
      </div>
    </div>
    <img
      src="@/assets/images/About-problem-1-content-7.svg"
      alt="hero"
      class="px-5"
    />
    <img
      src="@/assets/images/About-problem-1-content-creeping.svg"
      alt="hero"
      class="mt-100"
    />
    <img
      src="@/assets/images/About-problem-1-subtitle-3.svg"
      alt="hero"
      class="mt-100"
    />
    <div class="block-wrapper about-problem-1-tail">
      <img
        src="@/assets/images/About-problem-1-tail.svg"
        alt="hero"
        class="mt-100 px-5"
      />
      <h3 class="source">
        Источник:
        <a
          href="https://spb.tpprf.ru/ru/events/members-news/bolshe-poloviny-it-proektov-provalivayutsya-pochemu-i500315/"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >tpprf.ru</a
        >
      </h3>
    </div>
    <img
      src="@/assets/images/About-problem-1-content-8.svg"
      alt="hero"
      class="mt-10"
    />
    <img
      src="@/assets/images/About-problem-1-content-9.svg"
      alt="hero"
      class="mt-100 px-5"
    />
    <img
      src="@/assets/images/About-problem-1-content-10.svg"
      alt="hero"
      class="mt-100"
    />
    <div class="divider-horizontal mt-100 mb-0"></div>
    <img
      src="@/assets/images/About-problem-2-title.svg"
      alt="hero"
      class="mb-5 mt-100"
      style="width: 40%"
    />
    <img
      src="@/assets/images/About-problem-2-subtitle.svg"
      alt="hero"
      class="px-5"
    />
    <div class="divider-vertical my-4" style="height: 30px"></div>
    <img
      src="@/assets/images/About-problem-2-subtitle-2.svg"
      alt="hero"
      class="px-5"
    />
    <img
      src="@/assets/images/About-problem-2-content.svg"
      alt="hero"
      class="px-5 mt-10"
    />
    <img
      src="@/assets/images/About-problem-2-content-tail.svg"
      alt="hero"
      class="mt-100 px-5"
    />
    <img
      src="@/assets/images/About-problem-2-content-2.svg"
      alt="hero"
      class="mt-20 px-5"
    />
    <img
      src="@/assets/images/About-problem-2-content-3.svg"
      alt="hero"
      class="mt-10"
    />
    <div class="block-wrapper about-problem-2-tail-2">
      <img
        src="@/assets/images/About-problem-2-content-tail-2.svg"
        alt="hero"
        class="px-5"
      />
      <h3 class="source">
        Источник:
        <a
          href="https://www.gartner.com/en/newsroom/press-releases/2024-04-16-gartner-forecast-worldwide-it-spending-to-grow-8-percent-in-2024"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >gartner.com</a
        >
      </h3>
    </div>
    <img
      src="@/assets/images/About-problem-2-content-4.svg"
      alt="hero"
      style="margin-top: -30px"
    />
    <ProblemBlock15 />
    <div class="divider-horizontal mt-100 mb-0"></div>
    <img
      src="@/assets/images/About-problem-3-title.svg"
      alt="hero"
      class="mb-5 mt-100 w-50"
    />
    <img
      src="@/assets/images/About-problem-3-subtitle.svg"
      alt="hero"
      class="w-75"
    />
    <div class="block-wrapper about-problem-3-content">
      <img
        src="@/assets/images/About-problem-3-content.svg"
        alt="hero"
        class="mt-100 px-5"
      />
      <h3 class="source">
        Источник:
        <a
          href="https://cybersecurityventures.com/hackerpocalypse-cybercrime-report-2016/#:~:text=Cybersecurity%20Ventures%20expects%20global%20cybercrime,%243%20trillion%20USD%20in%202015."
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >cybersecurityventures.com</a
        >
      </h3>
    </div>
    <div class="block-wrapper about-problem-3-graphic">
      <img
        src="@/assets/images/About-problem-3-graphic.svg"
        alt="hero"
        class="mt-100 px-5"
      />
      <h3 class="source">
        Источник:
        <a
          href="https://cybersecurityventures.com/hackerpocalypse-cybercrime-report-2016/#:~:text=Cybersecurity%20Ventures%20expects%20global%20cybercrime,%243%20trillion%20USD%20in%202015."
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >cybersecurityventures.com</a
        >
      </h3>
    </div>
    <img
      src="@/assets/images/About-problem-3-diagram-1.svg"
      alt="hero"
      class="mt-100 px-5"
    />
    <img
      src="@/assets/images/About-problem-3-diagram-2.svg"
      alt="hero"
      class="mt-100 px-10"
    />
    <img
      src="@/assets/images/About-problem-3-content-2.svg"
      alt="hero"
      class="mt-10 px-5 w-75"
    />
    <img
      src="@/assets/images/About-problem-3-content-3.svg"
      alt="hero"
      class="mt-10 px-5 w-75"
    />
    <img
      src="@/assets/images/About-problem-3-subtitle-3.svg"
      alt="hero"
      class="mt-10 px-5 w-75"
    />
    <img
      src="@/assets/images/About-problem-3-content-4.svg"
      alt="hero"
      class="mt-10 px-5 w-75"
    />
    <ProblemBlock21 />
    <img
      src="@/assets/images/About-problem-3-content-5.svg"
      alt="hero"
      class="mt-10 px-5 w-75"
    />
    <div class="divider-horizontal mt-100 mb-0"></div>
    <img
      src="@/assets/images/About-problem-4-title.svg"
      alt="hero"
      class="mb-6 mt-100"
      style="width: 27%"
    />
    <img
      src="@/assets/images/About-problem-4-subtitle.svg"
      alt="hero"
      class="px-5 mb-6"
    />
    <img
      src="@/assets/images/About-problem-4-content.svg"
      alt="hero"
      class="px-5 w-75"
    />
    <img
      src="@/assets/images/About-problem-4-content-2.svg"
      alt="hero"
      class="w-75"
    />
    <img
      src="@/assets/images/About-problem-4-illustration.svg"
      alt="hero"
      class="mt-20"
    />
    <img
      src="@/assets/images/About-problem-4-subtitle-2.svg"
      alt="hero"
      class="mt-20 w-75"
    />
    <ProblemBlock24 />
    <img
      src="@/assets/images/About-problem-4-content-3.svg"
      alt="hero"
      class="w-75"
    />
    <img
      src="@/assets/images/About-problem-4-content-4.svg"
      alt="hero"
      class="w-75 mt-20"
    />
    <div class="divider-horizontal mt-100 mb-0"></div>
    <img
      src="@/assets/images/About-problem-5-title.svg"
      alt="hero"
      class="mb-5 mt-100"
      style="width: 30%"
    />
    <img
      src="@/assets/images/About-problem-5-subtitle.svg"
      alt="hero"
      class="w-75"
    />
    <ProblemBlock25 />
    <img
      src="@/assets/images/About-problem-5-content.webp"
      alt="hero"
      class="px-10"
    />
    <img
      src="@/assets/images/About-problem-5-content-2.svg"
      alt="hero"
      class="w-75 px-5 mt-15"
    />
    <img
      src="@/assets/images/About-problem-5-content-3.svg"
      alt="hero"
      class="w-75 px-5 mt-15"
    />
    <img
      src="@/assets/images/About-problem-5-tail.svg"
      alt="hero"
      class="w-75"
    />
    <img
      src="@/assets/images/About-problem-5-content-4.svg"
      alt="hero"
      class="w-75 px-5 mt-10"
    />
    <img
      src="@/assets/images/About-problem-5-subtitle-2.svg"
      alt="hero"
      class="w-75 mt-15"
    />
    <img
      src="@/assets/images/About-problem-5-content-5.svg"
      alt="hero"
      class="w-75 mt-15"
    />
    <img
      src="@/assets/images/About-problem-5-title-2.svg"
      alt="hero"
      class="mt-10"
      style="width: 65%"
    />
    <div class="px-4">
      <ContactForm />
    </div>
  </div>
</template>

<script>
import AboutProblemHero from "@/components/AboutProblem/AboutProblemHero.vue";
import ContactForm from "@/components/ContactForm.vue";
import ProblemBlock15 from "@/components/AboutProblem/ProblemBlock15.vue";
import ProblemBlock21 from "@/components/AboutProblem/ProblemBlock21.vue";
import ProblemBlock24 from "@/components/AboutProblem/ProblemBlock24.vue";
import ProblemBlock25 from "@/components/AboutProblem/ProblemBlock25.vue";

export default {
  name: "MobileAboutProblemBlock",
  components: {
    ProblemBlock25,
    ProblemBlock24,
    ProblemBlock21,
    ProblemBlock15,
    ContactForm,
    AboutProblemHero,
  },
};
</script>

<style scoped lang="sass">
.page-mobile
  position: relative
  display: flex
  flex-direction: column
  justify-content: start
  align-items: center

  .mt-150
    margin-top: 150px

  .mt-100
    margin-top: 100px

  img
    width: 100%
    height: auto
    object-fit: cover

  .block-wrapper
    position: relative
    width: 100%

    .source
      opacity: 0.5
      font-size: 36px
      font-weight: 400
      line-height: 38px
      margin: 20px auto

      .link
        text-decoration: underline

    @media (max-width: 740px)
    .source
      font-size: 22px
      line-height: 27px

    @media (max-width: 470px)
      .source
        font-size: 10px
        line-height: 14px

    .tile-wrapper
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      z-index: 2

      .google
        position: absolute
        top: 42%
        left: 9%
        width: 16%
        aspect-ratio: 5 / 1

      .facebook
        position: absolute
        top: 58%
        left: 45%
        width: 16%
        aspect-ratio: 5 / 1

      .amazon
        position: absolute
        top: 79.5%
        left: 10%
        width: 16%
        aspect-ratio: 6 / 1.3

      .uber
        position: absolute
        top: 96.5%
        left: 46%
        width: 16%
        aspect-ratio: 6 / 1.3

  .about-problem-1-content

    .source
      position: absolute
      top: 65%
      left: 50%
      transform: translate(-50%, 0 )

  .about-problem-1-content-3

    .source
      position: absolute
      top: 56%
      left: 70%
      transform: translate(-50%, 0 )

  .about-problem-1-content-4

    .source
      position: absolute
      bottom: 4%
      left: 22%
      transform: translate(-50%, 0 )

  .about-problem-1-tail
    .source
      position: absolute
      bottom: 1%
      left: 15%
      transform: translate(-50%, 0 )
      color: #000000

  .about-problem-2-tail-2
    .source
      position: absolute
      top: 5%
      right: -8%
      transform: translate(-50%, 0 )
      white-space: nowrap
      margin: 0

  .about-problem-3-content
    .source
      position: absolute
      bottom: 5%
      left: 63%
      transform: translate(-50%, 0 )
      white-space: nowrap
      margin: 0

  .about-problem-3-graphic
    .source
      position: absolute
      bottom: -4%
      left: 71%
      transform: translate(-50%, 0 )
      width: 25%
      margin: 0
</style>
