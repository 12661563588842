<template>
  <ModalComponent
    :isVisible="isModalVisible"
    @update:isVisible="closeModal"
    :showClose="false"
  >
    <div class="d-flex flex-column wrapper" @click="closeModal">
      <div class="menu">
        <router-link to="/" class="menu-item mr-2">Главная</router-link>
        <router-link to="/about-problem" class="menu-item mr-2"
          >О Проблеме</router-link
        >
        <router-link to="/about-gts" class="menu-item mr-2">О gts</router-link>
        <a
          href="https://ontonet.org/"
          class="menu-item"
          target="_blank"
          rel="noopener noreferrer"
        >
          блог
        </a>
        <div class="d-flex align-items-center">
          <router-link to="/contacts" class="btn-link">
            <span class="menu-item mb-0">Принять Участие</span>
            <img src="@/assets/icons/icon_go_to.svg" alt="Go to" class="ml-2" />
          </router-link>
        </div>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "MobileModalMenu",
  components: { ModalComponent },
  data() {
    return {
      isModalVisible: false,
      data: {},
    };
  },
  methods: {
    showModal(data) {
      window.locoScroll.stop();
      this.data = { ...data };
      this.isModalVisible = true;
    },
    closeModal() {
      window.locoScroll.start();
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 80%;
  padding: 40px 20px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 14px;
}

.menu-item {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.menu-item:last-child {
  margin-bottom: 0 !important;
}

.btn-link > img {
  width: 24px;
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 30px 3px 10px;
  border: none;
  background-color: #fff;
  color: var(--black);
  text-decoration: none;
  border-radius: 20px;
}
</style>
