<template>
  <div class="wrapper">
    <div class="hero">
      <img src="@/assets/images/Hero-mobile.png" alt="hero" />
      <HeroScreensaver class="hero-text" ref="heroText" />
    </div>
    <img src="@/assets/images/Orb-mobile.svg" alt="orb" class="mt-150" />
    <img
      src="@/assets/images/Mobile-Block-3.svg"
      alt="orb"
      class="mt-150 px-2"
    />
    <img src="@/assets/images/Mobile-Block-4.svg" alt="orb" class="mt-150" />
    <div class="divider-horizontal"></div>
    <img src="@/assets/images/Mobile-Block-5.svg" alt="orb" />
    <img
      src="@/assets/images/Mobile-Block-6.svg"
      alt="orb"
      class="mt-150 px-2"
    />
    <img src="@/assets/images/Mobile-Block-7.svg" alt="orb" class="mt-150" />
    <div class="divider-horizontal mt-100 mb-0"></div>
    <img
      src="@/assets/images/Mobile-Block-8.svg"
      alt="orb"
      class="mt-100 px-2"
    />
    <img src="@/assets/images/Mobile-Block-9.svg" alt="orb" class="mt-150" />
    <div class="graphic-container">
      <h2 class="graphic-title">
        Потери малого и среднего бизнеса в IT сфере и безопасности в РФ
      </h2>
      <h3 class="source">
        Источник:
        <a
          href="https://www.rbc.ru/"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >rbc.ru</a
        >
      </h3>
      <div class="scrollable-container">
        <img
          src="@/assets/images/Mobile-Graphic-1.png"
          alt="graphic"
          class="graphic"
        />
      </div>
    </div>
    <img
      src="@/assets/images/Mobile-Block-10.svg"
      alt="orb"
      class="mt-100 px-2"
    />
    <div class="block-wrapper">
      <img src="@/assets/images/Mobile-Block-11.svg" alt="orb" class="mt-100" />
      <h3 class="source">
        Источник:
        <a
          href="https://www.rbc.ru/technology_and_media/12/06/2024/6669be809a79473bda602920?ysclid=lz1q6agyh3553399805"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >rbc.ru</a
        >
      </h3>
    </div>
    <div class="divider-horizontal mt-5 mb-20"></div>
    <div class="block-wrapper">
      <img
        src="@/assets/images/Mobile-Block-12.webp"
        alt="orb"
        class="green-orb"
      />
      <img
        src="@/assets/images/Mobile-Block-12-title.svg"
        alt="orb"
        class="title"
      />
    </div>
    <img src="@/assets/images/Mobile-Block-12-subtitle.svg" alt="orb" />
    <img src="@/assets/images/Mobile-Block-13.svg" alt="orb" />
    <div class="divider-horizontal mt-5 mb-20"></div>
    <img src="@/assets/images/Mobile-Block-14.svg" alt="orb" />
    <div class="w-100 d-flex align-items-center justify-content-center my-10">
      <div class="divider-vertical-dashed" style="height: 140px"></div>
    </div>
    <img src="@/assets/images/Mobile-Block-15.svg" alt="orb" class="px-2" />
    <div class="w-100 d-flex align-items-center justify-content-center my-10">
      <div class="divider-vertical-dashed" style="height: 140px"></div>
    </div>
    <div class="block-wrapper">
      <img src="@/assets/images/Lego.webp" alt="orb" class="lego" />
      <img src="@/assets/images/Mobile-Block-16.svg" alt="orb" />
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center my-10">
      <div class="divider-vertical-dashed" style="height: 140px"></div>
    </div>
    <img src="@/assets/images/Mobile-Block-17.svg" alt="orb" />
    <img src="@/assets/images/Mobile-Block-18.svg" alt="orb" />
    <div class="divider-horizontal mt-5 mb-20"></div>
    <img src="@/assets/images/Mobile-Block-19.svg" alt="orb" />
    <img src="@/assets/images/Mobile-Block-20.svg" alt="orb" class="px-2" />
    <ProblemTailBlock @showProblemModal="openProblemModal" />
    <div class="main-problem-block">
      <img
        src="@/assets/images/Mobile-Block-21-frame.webp"
        alt="orb"
        class="frame"
      />
      <div class="content">
        <img src="@/assets/images/Mobile-Block-21-title.svg" alt="orb" />
        <div
          class="w-100 d-flex align-items-center justify-content-center my-10"
        >
          <div class="divider-vertical-dashed" style="height: 140px"></div>
        </div>
        <img src="@/assets/images/Mobile-Block-21-pain.svg" alt="orb" />
        <h2 class="title">Рост рынка IoE до 2030 года</h2>
        <h3 class="source">
          Источник:
          <a
            href="https://www.statista.com/statistics/976313/global-iot-market-size/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >statista.com</a
          >
        </h3>
        <div class="scrollable-container">
          <img
            src="@/assets/images/Mobile-Graphic-2.webp"
            alt="graphic"
            class="graphic"
          />
        </div>
        <div
          class="w-100 d-flex align-items-center justify-content-center my-10"
        >
          <div class="divider-vertical-dashed" style="height: 140px"></div>
        </div>
        <img src="@/assets/images/Mobile-Block-21-solution.svg" alt="orb" />
      </div>
    </div>
    <div class="divider-horizontal mt-100 mb-0"></div>
    <img src="@/assets/images/Mobile-Block-22.svg" alt="orb" class="mt-100" />
    <router-link to="/about-problem">
      <img src="@/assets/images/Button-about-problem.svg" alt="orb" />
    </router-link>
    <div class="divider-horizontal mt-100 mb-0"></div>
    <div class="w-100 d-flex align-items-center justify-content-center mt-100">
      <img
        src="@/assets/images/Mobile-Block-23-title.svg"
        alt="orb"
        class="w-75"
      />
    </div>
    <div class="d-flex flex-column align-items-center">
      <img
        src="@/assets/images/Mobile-Block-23-tile-1.svg"
        alt="orb"
        class="mt-100"
      />
      <div class="tile-2 d-flex flex-column align-items-center w-100">
        <img
          src="@/assets/images/Mobile-Block-23-tile-2.svg"
          alt="orb"
          class="mt-100"
          style="max-width: calc(100% - 40px)"
        />
        <h3 class="source">
          Источник:
          <a
            href="https://www.osp.ru"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >ops.ru</a
          >
        </h3>
      </div>
      <img
        src="@/assets/images/Mobile-Block-23-tile-3.svg"
        alt="orb"
        class="mt-100"
        style="max-width: calc(100% - 40px)"
      />
    </div>
    <div class="px-4">
      <img
        src="@/assets/images/Mobile-Block-24-title.svg"
        alt="orb"
        class="mt-100"
      />
      <div class="divider-horizontal mt-10 mb-0"></div>
      <img src="@/assets/images/Mobile-Block-24.svg" alt="orb" class="mt-100" />
      <router-link to="/about-gts">
        <img src="@/assets/images/Button-about-gts.svg" alt="orb" />
      </router-link>
      <div class="divider-horizontal mt-100 mb-0"></div>
      <img
        src="@/assets/images/About-team-title.svg"
        alt="orb"
        class="mt-100 mb-10"
      />
      <div class="team">
        <img src="@/assets/images/About-team.png" alt="orb" />
        <img
          v-for="author in getAuthors"
          :key="author.id"
          :src="require(`@/assets/images/${author.image}`)"
          :style="`position: absolute; top:${author.topMobile}%; right:${author.rightMobile}%; scale: ${author.scale}`"
          :alt="author.name"
          class="author"
          @click="openModal(author.id)"
        />
      </div>
    </div>
    <div class="px-4">
      <ContactForm />
    </div>
  </div>
</template>

<script>
import HeroScreensaver from "@/components/HomeView/HeroScreensaver.vue";
import eventBus from "@/utils/eventsBus";
import { mapGetters } from "vuex";
import ProblemTailBlock from "@/components/mobileHome/ProblemTailBlock.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "HomeMobileBlock1",
  components: { ContactForm, ProblemTailBlock, HeroScreensaver },
  computed: {
    ...mapGetters(["getAuthorById", "getCardById", "getAuthors"]),
  },
  methods: {
    openProblemModal(id) {
      const problem = this.getCardById(id);
      eventBus.emit("openProblemModal", problem);
    },
    openModal(id) {
      const author = this.getAuthorById(id);
      eventBus.emit("openAuthorModal", author);
    },
  },
};
</script>

<style scoped lang="sass">
.wrapper
  .mt-150
    margin-top: 150px

  .mt-100
    margin-top: 100px

  img
    width: 100%
    height: auto
    object-fit: cover

  .source
    opacity: 0.5
    font-size: 36px
    font-weight: 400
    line-height: 38px
    margin: 20px auto

    .link
      text-decoration: underline

  .tile-2
    position: relative

    .source
      position: absolute
      left: 50%
      transform: translateX(-50%)
      bottom: 4%

  @media (max-width: 740px)
    .source
      font-size: 22px
      line-height: 27px

  @media (max-width: 470px)
    .source
      font-size: 14px
      line-height: 17px

  .scrollable-container
    overflow-x: auto
    display: flex
    justify-content: start
    align-items: center
    overflow-y: hidden
    -ms-overflow-style: none
    scrollbar-width: none
    width: 100%
    cursor: grab

    &::-webkit-scrollbar
      display: none

    .graphic
      min-width: 600px
      max-width: none

  .hero
    position: relative

    img
      width: 100%
      height: auto

    .hero-text
      position: absolute
      top: 0
      left: 50%
      transform: translate(-50%, 0)
      height: 100vh
      padding: 0 10px

  .graphic-container
    position: relative
    margin-top: 100px
    font-size: 36px
    font-weight: 400
    line-height: 65px
    letter-spacing: -0.3574px
    text-align: center
    text-underline-position: from-font
    text-decoration-skip-ink: none

    .graphic-title
      max-width: 80%
      margin: 0 auto
      font-size: 36px
      font-weight: 400
      line-height: 65px
      letter-spacing: -0.3574px
      text-align: center
      text-underline-position: from-font
      text-decoration-skip-ink: none

    .graphic-container
      position: relative
      margin-top: 100px
      font-size: 36px
      font-weight: 400
      line-height: 65px
      letter-spacing: -0.3574px
      text-align: center

    @media (max-width: 640px)
      .graphic-title
        font-size: 22px
        line-height: 24px
        letter-spacing: -0.4624px

    @media (max-width: 500px)
      .graphic-title
        font-size: 14px
        line-height: 17px
        letter-spacing: -0.4624px

  .block-wrapper
    position: relative

    .source
      margin: 20px auto
      position: absolute
      bottom: 5%
      left: 50%
      transform: translate(-50%, 0)

    .title
      position: absolute
      bottom: 0
      left: 50%
      transform: translate(-50%, 0)
      z-index: 2
      max-width: 70%


    .lego
      position: absolute
      top: 0
      left: 50%
      transform: translate(-50%, 0)

  .main-problem-block
    padding: 0 20px
    position: relative

    .frame
      position: absolute
      top: 0
      left: 50%
      transform: translate(-50%, 0)
      width: calc(100% - 40px)
      z-index: -1

    .content
      position: relative
      z-index: 1
      padding: 40px 0 0 0
      display: flex
      flex-direction: column
      align-items: center

    .source
      margin-bottom: 5px

      .title
        font-size: 38px
        font-weight: 400
        line-height: 42px
        letter-spacing: -0.3329px
        text-align: center
        text-underline-position: from-font
        text-decoration-skip-ink: none
        margin-top: 40px

      @media (max-width: 740px)
        .title
          font-size: 22px
          line-height: 27px


      @media (max-width: 470px)
        .title
          font-size: 12px
          line-height: 14.4px

  .team
    position: relative

    .author
      border-radius: 50px
      max-width: 85px
      height: auto
      cursor: pointer
</style>
