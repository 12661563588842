<template>
  <div class="page" v-if="!isMobile">
    <AboutProblemHero />
    <ProblemBlock1 />
    <ProblemBlock2 />
    <ProblemBlock3 />
    <ProblemBlock4 />
    <ProblemBlock5 />
    <ProblemBlock6 />
    <ProblemBlock7 />
    <CreepingTemporary />
    <ProblemBlock8 />
    <ProblemBlock9 />
    <ProblemBlock10 />
    <ProblemBlock11 />
    <ProblemBlock12 />
    <ProblemBlock13 />
    <ProblemBlock14 />
    <ProblemBlock15 />
    <ProblemBlock16 />
    <ProblemBlock17 />
    <ProblemBlock18 />
    <ProblemBlock19 />
    <ProblemBlock20 />
    <ProblemBlock21 />
    <ProblemBlock22 />
    <ProblemBlock23 />
    <ProblemBlock24 />
    <ProblemBlock25 />
    <ProblemBlock26 />
    <ProblemBlock27 />
    <ContactForm />
  </div>
  <MobileAboutProblemBlock v-if="isMobile" />
</template>

<script>
import AboutProblemHero from "@/components/AboutProblem/AboutProblemHero.vue";
import ProblemBlock1 from "@/components/AboutProblem/ProblemBlock1.vue";
import ProblemBlock2 from "@/components/AboutProblem/ProblemBlock2.vue";
import ProblemBlock3 from "@/components/AboutProblem/ProblemBlock3.vue";
import ProblemBlock4 from "@/components/AboutProblem/ProblemBlock4.vue";
import ProblemBlock5 from "@/components/AboutProblem/ProblemBlock5.vue";
import ProblemBlock6 from "@/components/AboutProblem/ProblemBlock6.vue";
import ProblemBlock7 from "@/components/AboutProblem/ProblemBlock7.vue";
import CreepingTemporary from "@/components/AboutProblem/CreepingTemporary.vue";
import ProblemBlock8 from "@/components/AboutProblem/ProblemBlock8.vue";
import ProblemBlock9 from "@/components/AboutProblem/ProblemBlock9.vue";
import ProblemBlock10 from "@/components/AboutProblem/ProblemBlock10.vue";
import ProblemBlock11 from "@/components/AboutProblem/ProblemBlock11.vue";
import ProblemBlock12 from "@/components/AboutProblem/ProblemBlock12.vue";
import ProblemBlock13 from "@/components/AboutProblem/ProblemBlock13.vue";
import ProblemBlock14 from "@/components/AboutProblem/ProblemBlock14.vue";
import ProblemBlock15 from "@/components/AboutProblem/ProblemBlock15.vue";
import ProblemBlock16 from "@/components/AboutProblem/ProblemBlock16.vue";
import ProblemBlock17 from "@/components/AboutProblem/ProblemBlock17.vue";
import ProblemBlock18 from "@/components/AboutProblem/ProblemBlock18.vue";
import ProblemBlock19 from "@/components/AboutProblem/ProblemBlock19.vue";
import ProblemBlock20 from "@/components/AboutProblem/ProblemBlock20.vue";
import ProblemBlock21 from "@/components/AboutProblem/ProblemBlock21.vue";
import ProblemBlock22 from "@/components/AboutProblem/ProblemBlock22.vue";
import ProblemBlock23 from "@/components/AboutProblem/ProblemBlock23.vue";
import ProblemBlock24 from "@/components/AboutProblem/ProblemBlock24.vue";
import ProblemBlock25 from "@/components/AboutProblem/ProblemBlock25.vue";
import ProblemBlock26 from "@/components/AboutProblem/ProblemBlock26.vue";
import ProblemBlock27 from "@/components/AboutProblem/ProblemBlock27.vue";
import ContactForm from "@/components/ContactForm.vue";
import { mapMutations } from "vuex";
import eventBus from "@/utils/eventsBus";
import MobileAboutProblemBlock from "@/components/mobileAboutProblem/MobileAboutProblemBlock.vue";

export default {
  name: "AboutProblem",
  components: {
    MobileAboutProblemBlock,
    ContactForm,
    ProblemBlock27,
    ProblemBlock26,
    ProblemBlock25,
    ProblemBlock24,
    ProblemBlock23,
    ProblemBlock22,
    ProblemBlock21,
    ProblemBlock20,
    ProblemBlock19,
    ProblemBlock18,
    ProblemBlock17,
    ProblemBlock16,
    ProblemBlock15,
    ProblemBlock14,
    ProblemBlock13,
    ProblemBlock12,
    ProblemBlock11,
    ProblemBlock10,
    ProblemBlock9,
    ProblemBlock8,
    CreepingTemporary,
    AboutProblemHero,
    ProblemBlock1,
    ProblemBlock2,
    ProblemBlock3,
    ProblemBlock4,
    ProblemBlock5,
    ProblemBlock6,
    ProblemBlock7,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 920;
    },
  },
  methods: {
    ...mapMutations(["setBurgerItems"]),
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
    eventBus.emit("loadingTrigger", true);
    this.setBurgerItems([
      {
        title: "Проблема адаптации",
        path: "#block-1",
        offset: -150,
      },
      {
        title: "проблема роста тсо",
        path: "#block-11",
        offset: -150,
      },
      {
        title: "РОСТ КИБЕРПРЕСТУПНОСТИ",
        path: "#block-16",
        offset: -150,
      },
      {
        title: "Остановка бизнес процессов",
        path: "#block-22",
        offset: -150,
      },
      {
        title: "Монополизация ниш",
        path: "#block-25",
        offset: -150,
      },
    ]);
  },
  beforeUnmount() {
    this.setBurgerItems({});
    window.removeEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style scoped></style>
