<template>
  <div class="page" v-if="!isMobile">
    <HeroBlock v-if="!showContent" @videoEnd="startContent" />
    <ContentBlock
      v-if="showContent"
      @revert="
        () => {
          this.showContent = false;
        }
      "
    />
  </div>
  <div class="page-mobile" v-if="isMobile">
    <HomeMobileBlock1 />
  </div>
</template>

<script>
import ContentBlock from "@/components/HomeView/ContentBlock.vue";
import HeroBlock from "@/components/HomeView/HeroBlock.vue";
import eventBus from "@/utils/eventsBus";
import HomeMobileBlock1 from "@/components/mobileHome/HomeMobileBlock1.vue";

export default {
  name: "HomeView",
  components: {
    HomeMobileBlock1,
    HeroBlock,
    ContentBlock,
  },
  data() {
    return {
      showContent: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    startContent() {
      this.showContent = true;
      window.locoScroll.update();
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 920;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  mounted() {
    eventBus.emit("loadingTrigger", true);
    window.addEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style></style>
