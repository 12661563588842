<template>
  <div class="wrapper">
    <AboutGtsBlock1 />
    <AboutGtsBlock2 />
    <img src="@/assets/images/About-gts-title.svg" alt="hero" class="w-75" />
    <img
      src="@/assets/images/About-gts-subtitle.svg"
      alt="hero"
      class="mt-50 px-5"
    />
    <div class="block-wrapper about-gts-tile">
      <img
        src="@/assets/images/About-gts-tile.svg"
        alt="hero"
        class="mt-50 px-5"
      />
      <h3 class="source">
        Источник:
        <a
          href="https://digital.gov.ru/uploaded/files/urovni-gotovnosti-tehnologii-gost-58048-2017.pdf"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >gov.ru</a
        >
      </h3>
    </div>
    <div class="divider-horizontal mt-5 mb-0"></div>
    <img src="@/assets/images/About-gts-title-2.svg" alt="hero" />
    <div class="block-wrapper about-gts-timeline">
      <img
        src="@/assets/images/About-gts-timeline.svg"
        alt="hero"
        class="px-5"
      />
      <h3 class="source kitov">
        Биография:
        <a
          href="https://www.computer-museum.ru/galglory/kitov0.htm"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >computer-museum.ru</a
        >
      </h3>
      <h3 class="source glushkov">
        Биография:
        <a
          href="https://glushkov.su/"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >glushkov.su</a
        >
      </h3>
    </div>
    <img
      src="@/assets/images/About-gts-text.svg"
      alt="hero"
      class="px-5 mt-100"
    />
    <div class="divider-vertical mt-50" style="height: 30px"></div>
    <img src="@/assets/images/About-gts-title-3.svg" alt="hero" class="px-5" />
    <div class="divider-vertical mt-0" style="height: 30px"></div>
    <img
      src="@/assets/images/About-gts-text-2.svg"
      alt="hero"
      class="px-5 mt-50"
    />
    <img src="@/assets/images/About-gts-text-3.svg" alt="hero" class="px-5" />
    <div class="divider-horizontal mt-100 mb-0"></div>
    <img src="@/assets/images/About-gts-text-block.svg" alt="hero" />
    <div class="divider-horizontal mt-50 mb-20"></div>
    <img
      src="@/assets/images/About-gts-text-block-2.svg"
      alt="hero"
      class="w-75"
    />
    <div class="px-4">
      <ContactForm />
    </div>
  </div>
</template>

<script>
import AboutGtsBlock1 from "@/components/AboutGts/AboutGtsBlock1.vue";
import AboutGtsBlock2 from "@/components/AboutGts/AboutGtsBlock2.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "MobileAboutGtsBlock",
  components: { ContactForm, AboutGtsBlock2, AboutGtsBlock1 },
};
</script>

<style scoped lang="sass">
.wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  .mt-50
    margin-top: 50px

  .mt-100
    margin-top: 100px

  img
    width: 100%
    height: auto
    object-fit: cover

  .block-wrapper
    position: relative
    width: 100%

    .source
      opacity: 0.5
      font-size: 36px
      font-weight: 400
      line-height: 38px
      color: white

      .link
        text-decoration: underline

    @media (max-width: 740px)
    .source
      font-size: 22px
      line-height: 27px

    @media (max-width: 470px)
      .source
        font-size: 10px
        line-height: 14px

  .about-gts-tile
    .source
      position: absolute
      bottom: 19%
      left: 50%
      transform: translateX(-50%)

  .about-gts-timeline
    .kitov
      position: absolute
      top: 14%
      right: 12.5%
      font-size: 10px
      line-height: 10px

    .glushkov
      position: absolute
      top: 25.5%
      right: 15%
      font-size: 10px
      line-height: 10px

    @media (max-width: 740px)
    .source
      font-size: 14px
      line-height: 14px

    @media (max-width: 470px)
      .source
        font-size: 6px
        line-height: 7px
</style>
