<template>
  <div class="wrapper">
    <div class="text-block">
      <h2 class="title mb-10">
        консорциум <span class="green">Онтосеть</span>
      </h2>
      <h3 class="subtitle">
        Ваш эксклюзивный<br />
        билет на рынки БРИКС+
      </h3>
    </div>
    <router-link to="/contacts" class="btn">
      <span>оставить заявку</span>
      <img src="../../assets/icons/icon_go_to.svg" alt="Go to" class="ml-7" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HeroScreensaver",
};
</script>

<style scoped>
.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  height: 100%;
  width: 100%;
  max-width: 1800px;
  aspect-ratio: 100 / 50;
  text-transform: uppercase;
}

.text-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 4;
}

.title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 63px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.subtitle {
  font-family: "Adieu Light", sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 57px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.green {
  color: #2da01a;
}

.btn {
  position: absolute;
  top: 77%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border: none;
  height: 52px;
  width: 240px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 30px;
  background-color: #fff;
  color: var(--black);
  text-decoration: none;
  border-radius: 100px;
  font-size: 14px;
}

@media (max-width: 760px) {
  .title {
    font-size: 42px;
  }

  .subtitle {
    font-size: 36px;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 30px;
    line-height: 36px;
  }

  .subtitle {
    font-size: 22px;
    line-height: 26px;
  }

  .btn {
    top: 70%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
</style>
