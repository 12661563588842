<template>
  <section class="wrapper" ref="blockSection" id="block-5">
    <h2 class="hero-title first">
      Санкции и высокая <br />
      конкуренция давят <br />
      на малый и средний <br />
      бизнес,
    </h2>
    <h2 class="sub-title my-15">
      в то время как крупные компании <br />
      укрепляют позиции за счет <br />
      инвестиций в инфраструктуру и <br />
      поглащение прибыли менее удачливых <br />
      участников рынка, перекладывая на <br />
      них часть своих издержек
    </h2>
    <h2 class="hero-title my-15 second">
      компании вынуждены <br />
      платить за призрачную <br />
      IT-безопасность.<br />Только в России потери<br />превысили
      <span class="outlined-white red">$10 млрд.</span>
    </h2>
    <div class="graphic my-15">
      <img
        class="graphic-layer img-graphic"
        src="@/assets/images/Graphic-layer.png"
        alt="Graphic"
      />
      <img
        class="points-layer img-graphic"
        src="@/assets/images/Points-layer.png"
        alt="Graphic"
      />
      <div class="hide-graphic"></div>
      <span class="graphic-title">
        Потери малого и среднего бизнеса <br />
        в IT сфере и безопасности в РФ
      </span>
      <a
        href="https://www.rbc.ru/"
        class="source-link"
        target="_blank"
        rel="noopener noreferrer"
        >Источник: <span class="underline">rbc.ru</span></a
      >
    </div>
    <h2 class="hero-title my-15 three">
      Отключение от мировых <br />
      сервисов лишает <br />
      конкурентоспособности <br />
      отечественный бизнес
    </h2>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "BlockSection5",
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    const blockSection = this.$refs.blockSection;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: blockSection,
        scroller: document.querySelector(".main-container"),
        start: "top 100%",
        end: "top -150%",
        scrub: 0.9,
        onEnter: () => {
          window.locoScroll.update();
        },
        onLeave: () => {
          window.locoScroll.update();
        },
      },
    });

    timeline.fromTo(
      blockSection.querySelector(".first"),
      { scale: 0.2, autoAlpha: 0, rotateX: 80 },
      { scale: 1, duration: 1, autoAlpha: 1, rotateX: 0 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".sub-title"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".second"),
      { scale: 0.2, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, duration: 1 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".hide-graphic"),
      { translateX: 0 },
      { translateX: "100%", duration: 0.5 },
      "-=0.5"
    );

    timeline.fromTo(
      blockSection.querySelector(".points-layer"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.5 },
      "=0"
    );

    timeline.fromTo(
      blockSection.querySelector(".graphic-title"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.5 },
      "=-2"
    );

    timeline.fromTo(
      blockSection.querySelector(".source-link"),
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.5 },
      "=-2"
    );

    timeline.fromTo(
      blockSection.querySelector(".three"),
      { translateY: 150, autoAlpha: 0 },
      { translateY: 0, autoAlpha: 1, duration: 0.5 },
      "=0"
    );
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.hero-title {
  font-family: "Adieu Bold", sans-serif;
  font-size: 55px;
  font-weight: 300;
  line-height: 66px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
}

.sub-title {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2879px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
}

.red {
  color: #fc3f1d;
}

.graphic {
  position: relative;
}

.img-graphic {
  width: 100vw;
  height: auto;
}

.points-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.hide-graphic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: black;
}

.graphic-title {
  position: absolute;
  left: 25px;
  top: 55px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3574px;
  text-align: left;
  z-index: 5;
}

.source-link {
  position: absolute;
  right: 20px;
  bottom: 15px;
  opacity: 0.5;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2879px;
  text-align: left;
  z-index: 5;
}

@media (max-width: 1000px) {
  .hero-title {
    font-size: 38px;
    line-height: 48px;
  }
}

@media (max-width: 750px) {
  .hero-title {
    font-size: 28px;
    line-height: 38px;
  }
}
</style>
