<template>
  <ModalComponent :isVisible="isModalVisible" @update:isVisible="closeModal">
    <div class="d-flex align-items-stretch wrapper" @click="closeModal">
      <div class="tail flex-grow-1">
        <img
          :src="require(`@/assets/images/${data.backgroundModal}`)"
          alt="background"
          class="image"
        />
        <span class="tile-title">{{ data.title }}</span>
        <div class="tile-description">
          {{ data.description }}
        </div>
      </div>
      <div class="tail-content d-flex flex-column align-items-stretch">
        <div class="flex-grow-1">
          <span class="title outlined-red mb-5">Боль</span>
          <p class="description">{{ data.pain }}</p>
        </div>
        <div class="divider-horizontal w-100 my-5"></div>
        <div class="flex-grow-1">
          <span class="title outlined-green mb-5">Решение</span>
          <p class="description">{{ data.solution }}</p>
        </div>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "ModalAboutProblem",
  components: { ModalComponent },
  data() {
    return {
      isModalVisible: false,
      data: {},
    };
  },
  methods: {
    showModal(data) {
      window.locoScroll.stop();
      this.data = { ...data };
      this.isModalVisible = true;
    },
    closeModal() {
      window.locoScroll.start();
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 1400px;
  max-height: 80vh;
  height: auto;
  padding: 0;
  border-radius: 46px;
}

.tail {
  position: relative;
  max-width: 500px;
  width: 100%;
  border-radius: 46px;
  flex-grow: 1;
}

.tile-title {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 20px;
  font-weight: 550;
  line-height: 24px;
  letter-spacing: -0.086px;
  text-align: left;
  border: 1px solid #ffffff;
  padding: 8px 20px;
  border-radius: 24px;
}

.tile-description {
  position: absolute;
  bottom: 25px;
  left: 25px;
  font-size: 48px;
  font-weight: 550;
  line-height: 58px;
  letter-spacing: -0.2879px;
  text-align: left;
  text-transform: uppercase;
}

.tail-content {
  padding: 40px;
  width: 100%;
  flex-grow: 1;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 46px;
}

.title {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 550;
  line-height: 29px;
  letter-spacing: -0.086px;
  text-align: left;
  display: inline-block;
}

.description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}

@media (max-width: 1600px) {
  .description {
    font-size: 16px;
  }
}

@media (max-width: 1440px) {
  .wrapper {
    width: 1200px;
  }
}

@media (max-width: 1240px) {
  .wrapper {
    width: 1000px;
  }
}

@media (max-width: 1040px) {
  .wrapper {
    width: 700px;
  }

  .tile-title {
    font-size: 18px;
  }

  .tile-description {
    font-size: 32px;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  .description {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 760px) {
  .wrapper {
    width: 100%;
    max-height: 85vh;
    height: auto;
    flex-direction: column;
    padding: 40px 20px 0 20px;
  }

  .tail-content {
    padding-left: 0;
  }

  .image {
    display: none;
  }

  .tile-title {
    font-size: 18px;
    position: static;
  }

  .tile-description {
    position: static;
    font-size: 24px;
    line-height: 27px;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    margin-top: 30px;
  }

  .description {
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
