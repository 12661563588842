<template>
  <section class="wrapper" id="block-21">
    <div class="tile-row">
      <div class="white-card">
        <h3 class="tile-content">
          Никто не в безопасности… просто кто-то имеет чуть больший запас
          прочности.
        </h3>
        <p class="text">
          Безопасность критической инфраструктуры становится ключевым вопросом в
          условиях глобальной цифровизации.
        </p>
      </div>
      <div class="black-card">
        <div class="buttons-row">
          <div
            class="button"
            :class="{ active: activeButton === 0 }"
            @click="selectCard(0)"
          >
            вчера
          </div>
          <div
            class="button"
            :class="{ active: activeButton === 1 }"
            @click="selectCard(1)"
          >
            сегодня
          </div>
          <div
            class="button"
            :class="{ active: activeButton === 2 }"
            @click="selectCard(2)"
          >
            завтра
          </div>
        </div>
        <img
          :src="require(`@/assets/images/${selectedCard.image}`)"
          alt="image"
          class="my-20 image"
          style="height: 200px; width: auto"
        />
        <p class="card-description my-5">
          {{ selectedCard.title }}
        </p>
      </div>
    </div>
    <h2 class="title">
      базовый стек технологий<br />
      «GTS» - ваш вклад в Победу над<br />
      кибер-преступностью как<br />
      сверхприбыльным бизнесом.
    </h2>
    <div class="divider-horizontal"></div>
  </section>
</template>

<script>
export default {
  name: "ProblemBlock21",
  data() {
    return {
      activeButton: 0,
      cardsInfo: [
        {
          title: "Вчера кибер-преступником была хакерская группа",
          image: "yesterday_image.png",
        },
        {
          title: "Сегодня кибер-преступником является государство",
          image: "today_image.png",
        },
        {
          title: "Завтра кибер-преступником будет блок стран",
          image: "tomorrow_image.png",
        },
      ],
    };
  },
  computed: {
    selectedCard() {
      return this.cardsInfo[this.activeButton];
    },
  },
  methods: {
    selectCard(index) {
      this.activeButton = index;
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  margin-top: 150px;
  padding: 0 20px;
  text-transform: uppercase;
}

.title {
  font-size: 36px;
  font-weight: 550;
  line-height: 43px;
  letter-spacing: -0.2879px;
  text-align: center;
  margin-top: 150px;
}

.tile-row {
  display: flex;
  align-items: stretch;
  border: 2px solid #333;
  border-radius: 40px;
  max-width: 1150px;
  width: 100%;
  min-height: 50px;
  height: 100%;
}

.white-card {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 20px;
  padding: 34px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  border: 1px solid #333;
  color: black;
}

.black-card {
  position: relative;
  flex-grow: 1;
  padding: 34px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.black-card::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3),
    transparent 40%
  );
  pointer-events: none;
  z-index: -1;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.tile-content {
  font-size: 40px;
  font-weight: 550;
  line-height: 48px;
  letter-spacing: -0.2879px;
  text-align: left;
}

.card-title {
  font-size: 200px;
  font-weight: 550;
  line-height: 240px;
  letter-spacing: -0.2879px;
  text-align: center;
}

.card-description {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.2879px;
  text-align: center;
  max-width: 450px;
}

.buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff33;
  border-radius: 40px;
  padding: 5px;
  gap: 10px;
  width: 400px;
}

.button {
  border-radius: 40px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2879px;
  text-align: left;
  padding: 12px 20px;
  text-wrap: nowrap;
  cursor: pointer;
}

.active {
  background-color: #eb4a27;
}

@media (max-width: 1050px) {
  .tile-content {
    font-size: 36px;
  }
}

@media (max-width: 920px) {
  .wrapper {
    margin-top: 100px;
    margin-bottom: 0;
  }
  .divider-horizontal {
    display: none;
  }

  .title {
    display: none;
  }

  .white-card {
    padding: 7px;
  }
  .tile-content {
    font-size: 22px;
    line-height: 24px;
  }

  .text {
    font-size: 18px;
    line-height: 22px;
  }

  .button {
    font-size: 16px;
    line-height: 18px;
  }

  .image {
    margin: 40px auto !important;
  }

  .card-description {
    font-size: 16px;
    line-height: 20px;
    max-width: 300px;
  }
}

@media (max-width: 800px) {
  .text {
    font-size: 14px;
    line-height: 18px;
  }

  .card-description {
    font-size: 14px;
    line-height: 18px;
    max-width: 250px;
  }

  .buttons-row {
    max-width: 350px;
    padding: 0;
    gap: 5px;
  }
}

@media (max-width: 550px) {
  .tile-row {
    border-radius: 11px;
    padding: 0;
  }

  .white-card {
    padding: 7px;
    border-radius: 11px;
  }
  .tile-content {
    font-size: 12px;
    line-height: 14px;
  }

  .image {
    width: auto !important;
    height: 65px !important;
    margin: 10px 0 !important;
  }

  .text {
    font-size: 8px;
    line-height: 9px;
  }

  .buttons-row {
    max-width: 130px;
    padding: 0;
    gap: 5px;
  }

  .button {
    font-size: 6px;
    line-height: 8px;
    padding: 4px 8px;
  }

  .card-description {
    font-size: 6px;
    line-height: 8px;
    max-width: 130px;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

  .black-card {
    padding: 18px;
  }
}
</style>
